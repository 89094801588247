export const menuList = [
  {
    name: "Accueil",
    href: "/",
  },
  {
    name: "Nos services",
    items: [
      {
        title: "Affrêtement avion",
        // href: "/",
      },
      {
        title: "Location Jet privé",
        // href: "/",
      },
      {
        title: "Vente de billet d'avion",
        // href: "/",
      },
      {
        title: "Service courrier et frêt",
        //href: "/",
      },
      {
        // href: "/",
        title: "Facilitation visa",
      },
      {
        title: "Balissage aéroportuaire",
        //href: "/",
      },
      {
        title: "Courtage, manutention",
        // href: "/",
      },
      {
        title: "Placement de personnel",
        // href: "/",
      },
      {
        title: "Location de véhicules",
        // href: "/",
      },
    ],
  },
  {
    name: "A propos",
    href: "/a-propos",
  },
];
