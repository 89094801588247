import {
  alpha,
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

export default () => {
  const theme = useTheme();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        sx={{
          height: 500,
          p: 3,
          pb: 10,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(
        to bottom,
         ${alpha("#000", 0.3)},
         ${alpha("#000", 0.6)}
      ), url('/bannerIntro.jpg')`,
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color={"white"}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
            laboriosam veritatis provident, unde ipsum labore ad deleniti non
            officiis possimus suscipit incidunt ab voluptatibus tempora ipsa
            similique architecto cupiditate minus.
          </Typography>
        </Container>
      </Grid>
    </Box>
  );
};
