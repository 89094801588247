import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import theme from "../theme";

export const footerData = {
  entreprise: {
    title: "Entreprise",
    subtitles: [
      {
        title: "A propos de nous",
        path: "/a-propos",
      },
      {
        title: "Mentions Légales",
        path: "/mentions",
      },
    ],
  },

  prestations: {
    title: "Nos services",
    subtitles: [
      {
        id: 1,
        title: "Affrètement avion",
        image: "/travaux.jpg",
        details:
          "Vos projets deconstruction méritent un partenaire fiable et efficace. Petits ou grands travaux de construction de bâtiments, de rénovation, de génie Civil, des routes, ou d’ouvrages d’art. Nous ne faisons aucun compromis sur la qualité. Consol360 est spécialisée dans la construction, l'analyse de défauts, la recherche de pannes,...",
      },
      {
        id: 2,
        title: "Location Jet Privé",
        image: "/travaux.jpg",
        details:
          "Vos projets deconstruction méritent un partenaire fiable et efficace. Petits ou grands travaux de construction de bâtiments, de rénovation, de génie Civil, des routes, ou d’ouvrages d’art. Nous ne faisons aucun compromis sur la qualité. Consol360 est spécialisée dans la construction, l'analyse de défauts, la recherche de pannes,...",
      },
      {
        id: 3,
        title: "Vente de billets d'avion",
        image: "/nettoyage.jpg",
        details:
          "Consol360 nettoie, entretien, désinsectise, dératise et s'occupe des immondices de vos bureaux, maison, grande surface,...",
      },
      {
        id: 4,
        title: "Facilitation visa",
        image: "/nettoyage.jpg",
        details:
          "Gardiennage, Brigade canine, Escorte, convoyage des fonds, service de patrouille, de surveillance et d'intervention",
      },
      {
        id: 5,
        title: "Service courier et frêt",
        image: "/ressource.jpg",
        details:
          "La gestion des ressources humaines est une fonction essentielle dans la réussi et le développement de votre organisation. Elle représente une force motrice dans la mise en place et le pilotage de sa stratégie globale, pour rester performante et accomplir ses objectifs. CONSOL360 offre de service en recrutement, Intérim (local et expatrié), conseil, formation et externalisation de la paie en Afrique partenaire idéal pour le recrutement des tous travaux CONSOL360 vous aide à atteindre vos objectifs. ",
      },
      {
        id: 6,
        title: "Balisage aéroportuaire",
        image: "/airport.jpg",
        details:
          "Distribution, installation, et maintenance du balisage et équipements aéroportuaire.",
      },
      {
        id: 7,
        title: "Courtage, manutention",
        image: "/communication.jpg",
        details:
          "En tant qu'agence de communication 360, CONSOL360 couvre à la fois la stratégie, la production et la diffusion des campagnes marketing et communication sur tous vos points de contacts, aussi bien sur les canaux offline (TV, événement, RP, ...) et online (site vitrine ou e-commerce, social media, blog,...) ",
      },
      {
        id: 8,
        title: "Placement du personnel",
        image: "/communication.jpg",
        details:
          "En tant qu'agence de communication 360, CONSOL360 couvre à la fois la stratégie, la production et la diffusion des campagnes marketing et communication sur tous vos points de contacts, aussi bien sur les canaux offline (TV, événement, RP, ...) et online (site vitrine ou e-commerce, social media, blog,...) ",
      },
      {
        id: 9,
        title: "Location de véhicules",
        image: "/communication.jpg",
        details:
          "En tant qu'agence de communication 360, CONSOL360 couvre à la fois la stratégie, la production et la diffusion des campagnes marketing et communication sur tous vos points de contacts, aussi bien sur les canaux offline (TV, événement, RP, ...) et online (site vitrine ou e-commerce, social media, blog,...) ",
      },
    ],
  },
  communnity: {
    title: "Suivez-nous",
    socials: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/consolsarl?mibextid=ZbWKwL",
        icon: <FacebookIcon sx={{ color: theme.palette.secondary.main }} />,
      },
      {
        name: "Instagram",
        link: "https://instagram.com/congosolution360?igshid=ZDc4ODBmNjlmNQ==",
        icon: <InstagramIcon sx={{ color: theme.palette.secondary.main }} />,
      },
      // {
      //   name: "Linkedin",
      //   link: "https://linkedin.com/company/congosol360",
      //   icon: <LinkedInIcon sx={{ color: theme.palette.secondary.main }} />,
      // },
      {
        name: "Twitter",
        link: "https://twitter.com/consol360?t=_Gcxbfu1LBE__rbSSx1x2g&s=08",
        icon: <TwitterIcon sx={{ color: theme.palette.secondary.main }} />,
      },
    ],
  },
};

export const services = [
  {
    id: 1,
    title:
      "Bâtiment, Travaux public, Forage, Maintenance et Réparation générale",
    image: "/travaux.jpg",
    details:
      "Vos projets deconstruction méritent un partenaire fiable et efficace. Petits ou grands travaux de construction de bâtiments, de rénovation, de génie Civil, des routes, ou d’ouvrages d’art. Nous ne faisons aucun compromis sur la qualité. Consol360 est spécialisée dans la construction, l'analyse de défauts, la recherche de pannes,...",
  },
  {
    id: 2,
    title: "Agriculture,  Pêche & Élevage",
    image: "/agriculture.jpeg",
    details:
      "Polyculture, maraichage, arboriculture, horticulture, pépinière, élevage bovin, caprin, ovin …Transformation et conditionnement des produits de la ferme en fromages, confitures, charcuteries, Vente directe des produits de la ferme",
  },
  {
    id: 3,
    title:
      "Nettoyage, Entretien, Désinsectisation, Dératisation et Gestion des immondices.",
    image: "/nettoyage.jpg",
    details:
      "Consol360 nettoie, entretien, désinsectise, dératise et s'occupe des immondices de vos bureaux, maison, grande surface,...",
  },
  {
    id: 4,
    title: "Sécurité et Gardiennage",
    image: "/securite.jpg",
    details:
      "Gardiennage, Brigade canine, Escorte, convoyage des fonds, service de patrouille, de surveillance et d'intervention",
  },
  {
    id: 5,
    title: "Gestion & placement du personnel",
    image: "/ressource.jpg",
    details:
      "La gestion des ressources humaines est une fonction essentielle dans la réussi et le développement de votre organisation. Elle représente une force motrice dans la mise en place et le pilotage de sa stratégie globale, pour rester performante et accomplir ses objectifs. CONSOL360 offre de service en recrutement, Intérim (local et expatrié), conseil, formation et externalisation de la paie en Afrique partenaire idéal pour le recrutement des tous travaux CONSOL360 vous aide à atteindre vos objectifs. ",
  },
  {
    id: 6,
    title: "Balisage aéroportuaire",
    image: "/airport.jpg",
    details:
      "Distribution, installation, et maintenance du balisage et équipements aéroportuaire.",
  },
  {
    id: 7,
    title: "Communication 360º",
    image: "/communication.jpg",
    details:
      "En tant qu'agence de communication 360, CONSOL360 couvre à la fois la stratégie, la production et la diffusion des campagnes marketing et communication sur tous vos points de contacts, aussi bien sur les canaux offline (TV, événement, RP, ...) et online (site vitrine ou e-commerce, social media, blog,...) ",
  },
  {
    id: 8,
    title: "Eclairage publique",
    image: "/eclairage.jpg",
    details: "Distribution, installation et maintenance d'éclairage publique.",
  },
];

export const about = [
  {
    id: 1,
    details:
      " Nous Présentons les opportunités d'affaires disponibles en RDC aux potentiels bailleurs de fonds, aux investisseurs, aux partenaires de fabrication et sociétés commerciales.",
  },
  {
    id: 2,
    details:
      " Nous  Connectons les parties prenantes impliquées c'est-à-dire Les bailleurs de fonds, les investisseurs ou les partenaires commerciaux  aux décideurs présents en Republique Democratique du Congo et aux sociétés en Republique Democratique du Congo tout en atténuant les risques.",
  },
  {
    id: 3,
    details:
      " Nous Accompagnons et soutenons les potentiels bailleurs de fonds, les investisseurs, les partenaires et les sociétés commerciales dans l'enregistrement de leurs sociétés, co-entreprises et prise de participation. ",
  },
  {
    id: 4,
    details:
      "Nous Fournissons le soutien nécessaire aux bailleurs de fonds, investisseurs, partenaires et sociétés commerciales pour les positionner stratégiquement afin de sécuriser les affaires en RDC. Nous participons au processus de prise de décision pour atténuer tous les risques liés à l'exécution du projet.",
  },
];
