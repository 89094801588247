import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { footerData } from "../../data";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box sx={{ p: 5, backgroundColor: "#fff" }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography sx={{ display: { xs: "none", md: "flex" } }}>
              <img src={"/logo.png"} alt="logo" width={"70%"} />
            </Typography>
            <Typography sx={{ display: { xs: "flex", md: "none" } }}>
              <img src={"/logo.png"} alt="logo" width="30%" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              sx={{ color: theme.palette.secondary.main }}
              variant="h6"
            >
              {footerData.entreprise.title}
            </Typography>
            {footerData.entreprise.subtitles.map((item) => (
              <Button
                component="a"
                href={item.path}
                sx={{
                  fontSize: 17,
                  display: "block",
                  p: 0,
                  "&:hover": {
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                {item.title}
              </Button>
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              sx={{ color: theme.palette.secondary.main }}
              variant="h6"
            >
              {footerData.prestations.title}
            </Typography>
            {footerData.prestations.subtitles.map((item) => (
              <Button
                component="a"
                href={item.path}
                sx={{
                  fontSize: 17,
                  display: "block",
                  p: 0,
                  "&:hover": {
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                {item.title}
              </Button>
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              sx={{ color: theme.palette.secondary.main }}
              variant="h6"
            >
              {footerData.communnity.title}{" "}
            </Typography>
            <Box>
              {footerData.communnity.socials.map((social) => (
                <IconButton
                  component="a"
                  href={social.link}
                  sx={{
                    "&:hover": {
                      color: theme.palette.secondary.main,
                      fontSize: 25,
                    },
                  }}
                >
                  {social.icon}
                </IconButton>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
            <Typography
              textAlign="center"
              sx={{
                fontSize: 13,
                my: 2,
              }}
            >
              Copyright &copy; {new Date().getFullYear()}, Fabel Travel. Tous
              droits réservés.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
